<template>
  <div class="more">
    <div class="moreImg">
      <img src="../../assets/teachVideo.png" alt="">
    </div>
    <div class="moreTit">
      <ul>
        <li @click="toTab(1)" :class="tab == 1 ? 'moreActive' : ''">客户端教程</li>
        <li @click="toTab(2)" :class="tab == 2 ? 'moreActive' : ''">租户端教程</li>
      </ul>
    </div>
    <div class="moreCont">
      <div class="moreList" v-show="tab == 1">
        <div class="moreNav">
          <h2>客户端运--价类教程</h2>
          <div class="navCont">
            <ul>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="moreNav">
          <h2>客户端--操作类教程</h2>
          <div class="navCont">
            <ul>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="moreNav">
          <h2>客户端--财务类教程</h2>
          <div class="navCont">
            <ul>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="moreList" v-show="tab == 2">
        <div class="moreNav">
          <h2>租户端--运价类教程</h2>
          <div class="navCont">
            <ul>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
               <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="moreNav">
          <h2>租户端--操作类教程</h2>
          <div class="navCont">
            <ul>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
               <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="moreNav">
          <h2>租户端--财务类教程</h2>
          <div class="navCont">
            <ul>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <p>某某某教学视频</p>
              </li>
              <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
               <li>
                <video src="../../assets/video/video.mp4" controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                 <p>某某某教学视频</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
    import More from './More.js'
    export default More;
</script>

<style scoped>
    @import './More.css';
</style>