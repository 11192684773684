import More from './More.vue'
export default{
    components:{
        More
    },
    data(){
        return{
            tab:1
        }
    },
    methods:{
        toTab(index){
            this.tab = index
        }
    }
}